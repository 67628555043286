import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Typography, Collapse } from "@material-ui/core";
import { StarTwoTone } from "@material-ui/icons";

import _cloneDeep from "lodash.clonedeep";

import {
  RaffleActions,
  RaffleSelectors,
  SystemSelectors,
} from "../../../state";
import {
  parseNumber,
  formatCurrency,
  nth,
  pluralizeText,
  Navigation,
  isMobileView,
  isTabletView,
  getBannersToShow,
} from "../../../lib";
import ImageUpload from "../../../components/form/inputs/ImageWithCropUploadInput";
import Toggle from "../../../components/form/deprecated-inputs/ToggleDeprecated";
import EmailPreviewModal from "../enrollment/EmailPreviewModal";
import EmailRecipientsSection from "../../../components/EmailRecipientsSection";
import MetricsDisplaySection from "./MetricsDisplaySection";
import RequireDonorFieldsSection from "./RequireDonorFieldsSection";
import TeamsSection from "./TeamsSection";
import TicketOptionsSection from "./TicketOptionsSection";
import WebpageSection from "./WebpageSection";

export default function Settings(props) {
  const dispatch = useDispatch();

  //ACTIONS
  const submitEnrollment = RaffleActions.submitEnrollment;

  //SELECTORS
  const raffle = useSelector(RaffleSelectors.raffle);
  const banners = useSelector(SystemSelectors.banners);

  const {
    details: { isActive } = {},
    enrollmentDetails: {
      allowDonorToPayProcessingFee,
      allowPublicCreateReferrer,
      bonusRaffleGoal,
      campaignImages,
      campaignMessageOverride,
      chabadHouseID,
      cociTicketCCProcessingFee,
      currencyCode,
      defaultCampaignImages,
      defaultCampaignMessage,
      defaultEmailMessage,
      defaultTicketOption,
      donorGoal,
      donorListDisplay,
      emailMessageOverride,
      emailSignatureOverride,
      emailRecipients,
      fee,
      feeMax,
      id,
      includeEmailTaxReceipt,
      isDonorListPublic,
      isRaffleGoalPublic,
      prizes,
      raffleGoal,
      raffleGoalMessage,
      raffleTitle,
      requireDonorAddress,
      requireDonorPhone,
      showTopSellers,
      ticketOptions,
    },
  } = raffle;

  const isNewEnrollment = !id;

  //STATE
  const [settings, setSettings] = useState({});
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidEntryError, setInvalidEntryError] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [saveSucceeded, setSaveSucceeded] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showAllExpanded, setShowAllExpanded] = useState(!isActive);

  useEffect(() => {
    const defaultImages = defaultCampaignImages.map((img, i) => {
      return {
        url: img.url,
        isPrimary: i === 0, // first image is marked primary by default
      };
    });
    const _settings = {
      campaignMessageOverride: campaignMessageOverride || "",
      campaignImages: campaignImages?.length ? campaignImages : defaultImages,
      emailMessageOverride: emailMessageOverride || "",
      emailSignatureOverride: emailSignatureOverride || "",
      overrideDefaultCampaignMessage: (!!campaignMessageOverride).toString(),
      overrideDefaultEmailMessage: (!!emailMessageOverride).toString(),
      overrideDefaultEmailSignature: (!!emailSignatureOverride).toString(),
      raffleGoal: raffleGoal || "",
      raffleGoalMessage: raffleGoalMessage || "",
      isRaffleGoalPublic: !!isRaffleGoalPublic,
      bonusRaffleGoal: bonusRaffleGoal || "",
      donorGoal: donorGoal || "",
      isDonorListPublic: !!isDonorListPublic,
      donorListDisplay: donorListDisplay || "",
      defaultTicketOption: defaultTicketOption || "",
      ticketOptions,
      allowDonorToPayProcessingFee: !!allowDonorToPayProcessingFee,
      allowPublicCreateReferrer: !!allowPublicCreateReferrer,
      includeEmailTaxReceipt: !!includeEmailTaxReceipt,
      emailRecipients: emailRecipients || [],
      requireDonorAddress: requireDonorAddress || false,
      requireDonorPhone: isNewEnrollment ? true : requireDonorPhone || false,
      showTopSellers: showTopSellers || false,
    };
    setSettings(_settings);

    // all dependancies are pulled from raffle so only raffle needs to be in array
    // eslint-disable-next-line
  }, [raffle]);

  //METHODS
  const updateSettings = (settings) => {
    setSettingsChanged(true);
    setSettings(settings);
  };
  const handleChange = (name, value) => {
    const _settings = {
      ...settings,
      [name]: value,
    };
    updateSettings(_settings);
  };

  //separate method to handle multiple setting changes at once
  //to avoid one of them getting overwritten by multiple handleChange calls
  const handleBulkChange = (changedSettings) => {
    const newEnrollmentSettings = {
      ...settings,
      ...changedSettings,
    };
    updateSettings(newEnrollmentSettings);
  };

  const validate = useCallback(() => {
    if (
      isInvalid ||
      settings.emailRecipients.length === 0 ||
      (settings.overrideDefaultCampaignMessage === "true" &&
        !settings.campaignMessageOverride) ||
      settings.campaignImages.length < 5 ||
      (settings.overrideDefaultEmailMessage === "true" &&
        !settings.emailMessageOverride) ||
      (settings.overrideDefaultEmailSignature === "true" &&
        !settings.emailSignatureOverride) ||
      !settings.defaultTicketOption
    ) {
      setLoading(false);
      setInvalidEntryError("Please correct any invalid or missing settings.");
      return;
    }
    return true;
  }, [isInvalid, settings]);

  const save = useCallback(async () => {
    const {
      campaignImages,
      campaignMessageOverride,
      emailMessageOverride,
      emailSignatureOverride,
      overrideDefaultCampaignMessage,
      raffleGoal,
      raffleGoalMessage,
      isRaffleGoalPublic,
      bonusRaffleGoal,
      donorGoal,
      isDonorListPublic,
      donorListDisplay,
      defaultTicketOption,
      ticketOptions,
      allowDonorToPayProcessingFee,
      allowPublicCreateReferrer,
      includeEmailTaxReceipt,
      emailRecipients,
      requireDonorAddress,
      requireDonorPhone,
      showTopSellers,
    } = settings;

    const updatedEnrollment = _cloneDeep({
      ...raffle.enrollmentDetails,
      campaignImages,
      campaignMessageOverride,
      emailMessageOverride: emailMessageOverride || null,
      emailSignatureOverride: emailSignatureOverride || null,
      overrideDefaultCampaignMessage,
      raffleGoal,
      raffleGoalMessage,
      isRaffleGoalPublic: !!raffleGoal && isRaffleGoalPublic,
      bonusRaffleGoal,
      donorGoal,
      isDonorListPublic,
      donorListDisplay,
      defaultTicketOption,
      ticketOptions,
      allowDonorToPayProcessingFee,
      allowPublicCreateReferrer,
      includeEmailTaxReceipt,
      emailRecipients,
      requireDonorAddress,
      requireDonorPhone,
      showTopSellers,
    });

    const { success, errors } = await dispatch(
      submitEnrollment(updatedEnrollment),
    );
    if (!isNewEnrollment) {
      setLoading(false);
      setSettingsChanged(false);
      setSaveSucceeded(success);
      setErrorMessages(errors);

      //Success/error message shows when submitAttempted is true
      //Timeout will delay setting submitAttempted back to false so success/error msg will show for a few seconds
      setTimeout(() => {
        setSubmitAttempted(false);
      }, 2000);
    } else {
      props.setSubmitted();
    }
  }, [raffle, settings, isNewEnrollment, props, dispatch, submitEnrollment]);

  const onSubmit = useCallback(() => {
    setSubmitAttempted(true);
    setLoading(true);
    setInvalidEntryError("");
    setSaveSucceeded(false);
    setErrorMessages([]);

    const isValid = validate();
    if (isValid) {
      //clear override text fields if not overriding
      if (settings.overrideDefaultCampaignMessage === "false")
        settings.campaignMessageOverride = "";
      if (settings.overrideDefaultEmailMessage === "false")
        settings.emailMessageOverride = "";
      if (settings.overrideDefaultEmailSignature === "false")
        settings.emailSignatureOverride = "";

      //save ticket options in ascending order
      settings.ticketOptions.sort((a, b) => a - b);

      save();
    }
  }, [settings, save, validate]);

  //determine header position based on banner
  const bannersToShow = getBannersToShow(banners, Navigation.location.pathname);
  const getPositionForStickyHeader = () => {
    if (isMobileView() || isTabletView()) {
      return bannersToShow.length ? 104 : 56;
    }
    return bannersToShow.length ? 120 : 72;
  };

  const SaveBtnSection = () => (
    <div className="raffle-settings-btns flex flex-column flex-align-end">
      <button
        className="btn btn-accent btn-medium"
        disabled={isInvalid || (!isNewEnrollment && !settingsChanged)}
        onClick={onSubmit}
      >
        {loading ? "Saving..." : isNewEnrollment ? "Enroll" : "Save"}
      </button>

      {submitAttempted && saveSucceeded && (
        <p className="text-right mt-8 success-message relative">
          Your changes were saved successfully.
        </p>
      )}
      {submitAttempted && invalidEntryError && (
        <p className="text-right mt-8 error-message relative">
          {invalidEntryError}
        </p>
      )}
      {submitAttempted &&
        !!errorMessages.length &&
        errorMessages.map((error, i) => (
          <p key={i} className="text-right mt-8 error-message relative">
            {error}
          </p>
        ))}
    </div>
  );

  const defaultEmailSignature =
    "Sincerely,\nRabbi {Shliach first name} & Mrs. {Shlucha first name} {last name}";

  return (
    <div className="flex flex-align-top">
      <div
        className={`${
          !isActive ? "not-active " : ""
        } raffle-settings-container`}
      >
        <div
          className="raffles-sticky-header"
          style={{ top: getPositionForStickyHeader() }}
        >
          {isNewEnrollment ? (
            <p className="fs-34 fw-900 mobile-xxxl-text">
              Enroll in {raffleTitle}
            </p>
          ) : (
            <Typography variant="h5">
              My Grand Draw Settings
              {!isActive && (
                <span className="accent-text small-text block">
                  (Please Note: Settings can only be edited when raffle is
                  active)
                </span>
              )}
            </Typography>
          )}
          <div className="flex flex-align-center">
            <div>
              <span
                className="link-text-secondary accent-text"
                onClick={() => setShowAllExpanded(true)}
              >
                Expand all
              </span>
              <span className="accent-text mr-16 ml-16 small-text">|</span>
              <span
                className="link-text-secondary accent-text mr-40"
                onClick={() => setShowAllExpanded(false)}
              >
                Collapse all
              </span>
            </div>
            <SaveBtnSection />
          </div>
        </div>

        <Prompt
          when={settingsChanged}
          message="Your changes have not been saved. Are you sure you want to continue?"
        />

        <CampaignMessageSection
          submitAttempted={submitAttempted}
          isNewEnrollment={isNewEnrollment}
          defaultCampaignMessage={defaultCampaignMessage}
          overrideDefaultCampaignMessage={
            settings.overrideDefaultCampaignMessage
          }
          campaignMessageOverride={settings.campaignMessageOverride}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <CampaignImageSection
          campaignImages={settings.campaignImages}
          submitAttempted={submitAttempted}
          showAllExpanded={showAllExpanded}
          settings={settings}
          updateSettings={updateSettings}
        />
        <EmailMessageSection
          defaultEmailMessage={defaultEmailMessage}
          defaultEmailSignature={defaultEmailSignature}
          emailMessageOverride={settings.emailMessageOverride}
          emailSignatureOverride={settings.emailSignatureOverride}
          overrideDefaultEmailMessage={settings.overrideDefaultEmailMessage}
          overrideDefaultEmailSignature={settings.overrideDefaultEmailSignature}
          submitAttempted={submitAttempted}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <EmailSignatureSection
          defaultEmailSignature={defaultEmailSignature}
          emailSignatureOverride={settings.emailSignatureOverride}
          overrideDefaultEmailSignature={settings.overrideDefaultEmailSignature}
          submitAttempted={submitAttempted}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <EmailRecipientsSection
          chabadHouseID={chabadHouseID}
          emailRecipients={settings.emailRecipients}
          formSectionClassName="raffles-form-section"
          onChange={(recipientsChange) =>
            handleChange("emailRecipients", recipientsChange)
          }
          submitAttempted={submitAttempted}
          toggleExpand={true}
          showAllExpanded={showAllExpanded}
        />
        <GoalSection
          raffleGoal={settings.raffleGoal}
          bonusRaffleGoal={settings.bonusRaffleGoal}
          isRaffleGoalPublic={settings.isRaffleGoalPublic}
          raffleGoalMessage={settings.raffleGoalMessage}
          donorGoal={settings.donorGoal}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <DonorPanelSection
          isDonorListPublic={settings.isDonorListPublic}
          donorListDisplay={settings.donorListDisplay}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <TicketOptionsSection
          ticketOptions={settings.ticketOptions}
          defaultTicketOption={settings.defaultTicketOption}
          handleChange={handleChange}
          handleBulkChange={handleBulkChange}
          isInvalid={isInvalid}
          setIsInvalid={setIsInvalid}
          submitAttempted={submitAttempted}
          showAllExpanded={showAllExpanded}
        />
        <ProcessingFeeSection
          allowDonorToPayProcessingFee={settings.allowDonorToPayProcessingFee}
          handleChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <RequireDonorFieldsSection
          onChange={handleChange}
          requireDonorAddress={settings.requireDonorAddress}
          requireDonorPhone={settings.requireDonorPhone}
          showAllExpanded={showAllExpanded}
        />
        <TeamsSection
          allowPublicCreateReferrer={settings.allowPublicCreateReferrer}
          onChange={handleChange}
          showAllExpanded={showAllExpanded}
        />
        <MetricsDisplaySection
          onChange={handleChange}
          showTopSellers={settings.showTopSellers}
          showAllExpanded={showAllExpanded}
        />
        {isNewEnrollment ? (
          <AboutGrandDrawSection
            currencyCode={currencyCode}
            prizes={prizes}
            cociTicketCCProcessingFee={cociTicketCCProcessingFee}
            fee={fee}
            feeMax={feeMax}
            showAllExpanded={showAllExpanded}
          />
        ) : (
          <WebpageSection showAllExpanded={showAllExpanded} />
        )}
      </div>
    </div>
  );
}

const CampaignMessageSection = (props) => {
  const {
    submitAttempted,
    isNewEnrollment,
    defaultCampaignMessage,
    overrideDefaultCampaignMessage,
    campaignMessageOverride,
    handleChange,
    showAllExpanded,
  } = props;

  const maxCampaignMessageLength = 40;
  const [showExpanded, setShowExpanded] = useState(true);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };
  const ignoreShowAllExpanded = useRef(true);

  useEffect(() => {
    //Keep this section expanded when the page first opens
    if (ignoreShowAllExpanded.current) {
      ignoreShowAllExpanded.current = false;
    } else {
      setShowExpanded(showAllExpanded);
    }
  }, [showAllExpanded]);

  return (
    <>
      {isNewEnrollment && (
        <p className="raffles-form-section xxl-text fw-900 mb-24 mt-8">
          Set up the basics
        </p>
      )}
      <div className="raffles-form-section">
        <div className="raffles-section-title" onClick={handleToggleExpand}>
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Campaign Message
            </p>
          </div>
          {showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )}
        </div>
        <Collapse in={showExpanded}>
          <p className="accent-text-dark mb-24 line-height-double expanded-section">
            The campaign message will be displayed in large on the top section
            of your Grand Draw web page.
          </p>
          <div>
            <div className="radio-button-container mb-8">
              <div>
                <input
                  type="radio"
                  id="default"
                  name="overrideDefaultCampaignMessage"
                  value={false}
                  checked={overrideDefaultCampaignMessage === "false"}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <label
                  htmlFor="default"
                  style={{ fontSize: "14px", paddingLeft: "28px" }}
                >
                  Use the default campaign message
                </label>
              </div>
            </div>
            <p className="small-text fw-700 mb-24 ml-28 line-height-double">
              {defaultCampaignMessage}
            </p>
            <div className="relative">
              <div className="radio-button-container mb-16">
                <div className="flex">
                  <input
                    type="radio"
                    id="custom"
                    name="overrideDefaultCampaignMessage"
                    value={true}
                    checked={overrideDefaultCampaignMessage === "true"}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                  <label
                    htmlFor="custom"
                    style={{ fontSize: "14px", paddingLeft: "28px" }}
                  >
                    Create custom campaign message
                    <span className="accent-text small-text ml-24">
                      max {maxCampaignMessageLength} characters
                    </span>
                  </label>
                </div>
              </div>
              <textarea
                className="custom-input custom-message"
                maxLength={maxCampaignMessageLength}
                disabled={overrideDefaultCampaignMessage === "false"}
                value={campaignMessageOverride}
                placeholder="Type custom campaign message"
                name="campaignMessageOverride"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              {overrideDefaultCampaignMessage === "true" && (
                <p className="total-characters">
                  {campaignMessageOverride.length} / {maxCampaignMessageLength}
                </p>
              )}
              {submitAttempted &&
                overrideDefaultCampaignMessage === "true" &&
                !campaignMessageOverride && (
                  <p className="error-message" style={{ left: "28px" }}>
                    Please enter your custom campaign message
                  </p>
                )}
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const CampaignImageSection = (props) => {
  const {
    campaignImages,
    submitAttempted,
    showAllExpanded,
    settings,
    updateSettings,
  } = props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };
  const handleImageUpload = (value) => {
    const _settings = {
      ...settings,
      campaignImages: [
        ...campaignImages,
        { url: value, isPrimary: !campaignImages.length }, // first image uploaded is defaulted to primary
      ],
    };
    updateSettings(_settings);
  };
  const handleImageDelete = (value) => {
    const _settings = {
      ...settings,
      campaignImages: [...campaignImages.filter((i) => i.url !== value)],
    };
    updateSettings(_settings);
  };
  const handleSetImageIsPrimary = (imgUrl) => {
    const _settings = {
      ...settings,
      campaignImages: campaignImages.map((img) => ({
        ...img,
        isPrimary: img.url === imgUrl,
      })),
    };
    updateSettings(_settings);
  };
  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <>
      <div className="raffles-form-section campaign-image">
        <div className="raffles-section-title" onClick={handleToggleExpand}>
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Campaign Images
            </p>
          </div>
          {showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )}
        </div>
        <Collapse in={showExpanded}>
          <div className="expanded-section">
            <p className="accent-text-dark mb-16 line-height-double">
              Campaign images will be displayed in a revolving carousel on your
              Grand Draw web page. To give it a local feel, we suggest uploading
              images featuring students and local Chabad House activities. All
              images will be cropped to correct proportions upon upload. You can
              upload 5 to 10 images (we recommend 7-8) and star one image to set
              it as your feature image for link previews. After saving, review
              the display on your Grand Draw web page to ensure that you're
              satisfied with how they're displayed.
            </p>
            <div>
              {campaignImages?.length < 5 && (
                <p
                  className={`mb-12 ${
                    submitAttempted
                      ? "error-message relative"
                      : "warning-message"
                  }`}
                >
                  Please upload at least 5 images
                </p>
              )}
            </div>
            <div className="flex flex-align-center image-upload">
              {campaignImages?.map((img) => (
                <div
                  key={img.url}
                  className="profile-pic-container campaign-image mr-16"
                >
                  <StarTwoTone
                    className={`pointer star${img.isPrimary ? " filled" : ""}`}
                    onClick={() => handleSetImageIsPrimary(img.url)}
                  />
                  <img src={img.url} alt={img.url} />
                  <i
                    className="pointer material-icons close"
                    onClick={() => handleImageDelete(img.url)}
                  >
                    close
                  </i>
                </div>
              ))}

              <div className="mt-12">
                {campaignImages?.length < 10 && (
                  <ImageUpload
                    label="Upload Image"
                    cropAspectHeight={224}
                    cropAspectWidth={287}
                    onChange={(name, value) => handleImageUpload(value)}
                    imageType="banner"
                    hideDefaultImage={true}
                    forceCrop={true}
                  />
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const EmailMessageSection = (props) => {
  const {
    defaultEmailMessage,
    defaultEmailSignature,
    emailMessageOverride,
    emailSignatureOverride,
    overrideDefaultEmailMessage,
    overrideDefaultEmailSignature,
    submitAttempted,
    handleChange,
    showAllExpanded,
  } = props;

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };
  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Email Message
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>
      <Collapse in={showExpanded}>
        <div className="flex flex-justify-space mb-24 expanded-section">
          <div>
            <p className="accent-text-dark line-height-double">
              This text will be included in the email sent to donors with their
              purchase summary.
            </p>
          </div>
          <div>
            <p
              className="link-text small-text flex flex-align-center ml-16 no-wrap"
              onClick={() => setShowEmailPreviewModal(true)}
            >
              <i className="material-icons medium-text mr-8">email</i>
              Preview Full Email
            </p>
          </div>
        </div>
        <EmailPreviewModal
          close={() => setShowEmailPreviewModal(false)}
          emailMessage={
            overrideDefaultEmailMessage === "true"
              ? emailMessageOverride
              : defaultEmailMessage
          }
          emailSignature={
            overrideDefaultEmailSignature === "true"
              ? emailSignatureOverride
              : defaultEmailSignature
          }
          messageBorderColor={
            overrideDefaultEmailMessage === "true" ? "#E94746" : ""
          }
          signatureBorderColor={
            overrideDefaultEmailSignature === "true" ? "#E94746" : ""
          }
          show={showEmailPreviewModal}
        />
        <div>
          <div className="radio-button-container mb-8">
            <div>
              <input
                type="radio"
                id="defaultEmail"
                name="overrideDefaultEmailMessage"
                value={false}
                checked={overrideDefaultEmailMessage === "false"}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <label
                htmlFor="defaultEmail"
                style={{ fontSize: "14px", paddingLeft: "28px" }}
              >
                Use the default email message
              </label>
            </div>
          </div>
          <p
            className="small-text fw-700 mb-24 pre-wrap ml-28"
            style={{
              lineHeight: "1.5",
            }}
          >
            {defaultEmailMessage}
          </p>
          <div className="relative">
            <div className="radio-button-container mb-16">
              <input
                type="radio"
                id="customEmail"
                name="overrideDefaultEmailMessage"
                value={true}
                checked={overrideDefaultEmailMessage === "true"}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <label
                htmlFor="customEmail"
                style={{ fontSize: "14px", paddingLeft: "28px" }}
              >
                Create custom email message
              </label>
            </div>
            <textarea
              className="custom-input custom-message"
              disabled={overrideDefaultEmailMessage === "false"}
              value={emailMessageOverride || ""}
              placeholder="Type custom email message"
              name="emailMessageOverride"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              style={{ height: "180px" }}
            />
            {submitAttempted &&
              overrideDefaultEmailMessage === "true" &&
              !emailMessageOverride && (
                <p className="error-message" style={{ left: "28px" }}>
                  Please enter your custom email message
                </p>
              )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const EmailSignatureSection = (props) => {
  const {
    defaultEmailSignature,
    emailSignatureOverride,
    overrideDefaultEmailSignature,
    submitAttempted,
    handleChange,
    showAllExpanded,
  } = props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };
  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <div className="raffles-form-section email-signature-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Email Signature
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>
      <Collapse in={showExpanded}>
        <div className="flex flex-justify-space mb-24 expanded-section">
          <div>
            <p className="accent-text-dark line-height-double">
              Choose a custom email signature.
            </p>
          </div>
        </div>
        <p className="large-text fw-900 mb-8">Email Signature</p>
        <p className="accent-text-dark line-height-double">
          Choose a custom email signature.
        </p>
        <div className="radio-button-container mb-8">
          <div>
            <input
              type="radio"
              id="defaultEmailSignature"
              name="overrideDefaultEmailSignature"
              value={false}
              checked={overrideDefaultEmailSignature === "false"}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <label htmlFor="defaultEmailSignature" className="label">
              Use the default email signature
            </label>
          </div>
        </div>
        <p className="small-text fw-700 mb-24 pre-wrap ml-28 line-height-double">
          {defaultEmailSignature}
        </p>
        <div className="relative">
          <div className="radio-button-container mb-16">
            <input
              type="radio"
              id="customEmailSignature"
              name="overrideDefaultEmailSignature"
              value={true}
              checked={overrideDefaultEmailSignature === "true"}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <label htmlFor="customEmailSignature" className="label">
              Create custom email signature
            </label>
          </div>
          <textarea
            className="custom-input custom-message text-area"
            disabled={overrideDefaultEmailSignature === "false"}
            value={emailSignatureOverride || ""}
            placeholder="Type custom email signature"
            name="emailSignatureOverride"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          {submitAttempted &&
            overrideDefaultEmailSignature === "true" &&
            !emailSignatureOverride && (
              <p className="error-message error">
                Please enter your custom email signature
              </p>
            )}
        </div>
      </Collapse>
    </div>
  );
};

const GoalSection = (props) => {
  const {
    raffleGoal,
    bonusRaffleGoal,
    isRaffleGoalPublic,
    raffleGoalMessage,
    donorGoal,
    handleChange,
    showAllExpanded,
  } = props;

  const totalGoal = raffleGoal + bonusRaffleGoal;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <>
      <div className="raffles-form-section">
        <div className="raffles-section-title" onClick={handleToggleExpand}>
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Grand Draw Goals
            </p>
          </div>
          {showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )}
        </div>
        <Collapse in={showExpanded}>
          <p className="accent-text-dark mb-16 line-height-double expanded-section">
            Enter goals for your Grand Draw and we will help you keep track of
            your goals.
          </p>
          <p className="fw-700 mb-8">
            Ticket Goal <span className="accent-text fw-300">(optional)</span>
          </p>
          <p className="accent-text-dark mb-16 line-height-double">
            Enter a ticket goal. You can also add a bonus ticket goal to display
            once the initial ticket goal is reached. By making this goal public,
            your goal and message will be displayed on your public Grand Draw
            site.
          </p>
          <p className="accent-text-dark mb-16 line-height-double">
            Note: Think carefully about if you want to publicize this goal on
            your page. If you have a small goal that wouldn’t cover the prizes
            being offered, it may seem haphazard (rather than motivating!) to
            advertise it.
          </p>
          <div className="raffle-goal-input-container mb-16">
            <div className="flex mobile-block mb-16  raffle-goal-wrapper">
              <div>
                <p className="small-text accent-text mb-4">Goal</p>
                <div className="raffle-goal-input relative">
                  <NumberFormat
                    className="custom-input mr-16"
                    thousandSeparator={true}
                    name="raffleGoal"
                    value={raffleGoal}
                    onChange={(e) =>
                      handleChange("raffleGoal", parseNumber(e.target.value))
                    }
                  />
                </div>
              </div>
              <div>
                <p className="small-text accent-text mb-4">Bonus Goal</p>
                <div className="raffle-goal-input relative">
                  <NumberFormat
                    className="custom-input mr-16"
                    thousandSeparator={true}
                    name="bonusRaffleGoal"
                    value={bonusRaffleGoal}
                    onChange={(e) =>
                      handleChange(
                        "bonusRaffleGoal",
                        parseNumber(e.target.value),
                      )
                    }
                  />
                </div>
              </div>
              {!!totalGoal && (
                <p className="small-text accent-text mb-12">
                  Total Goal: {totalGoal} {pluralizeText("Ticket", totalGoal)}
                </p>
              )}
            </div>
            <Toggle
              disabled={!raffleGoal}
              name="isRaffleGoalPublic"
              onChange={handleChange}
              options={[
                {
                  value: true,
                  display: "Public",
                },
                {
                  value: false,
                  display: "Private",
                },
              ]}
              value={isRaffleGoalPublic}
            />
          </div>
          <p className="accent-text small-text mt-16 mb-8"></p>
          <textarea
            className="custom-input custom-message full-width mt-8"
            disabled={!isRaffleGoalPublic}
            value={raffleGoalMessage}
            placeholder="Type goal message here..."
            name="raffleGoalMessage"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <p className="fw-900 mb-8 mt-16">
            Donor Goal <span className="accent-text fw-300">(optional)</span>
          </p>
          <p className="accent-text-dark mb-16 line-height-double">
            Enter a donor goal. This goal will not be displayed publicly.
          </p>
          <div className="raffle-goal-input-container flex">
            <div className="raffle-goal-input donor-goal-input relative">
              <NumberFormat
                className="custom-input mr-16"
                thousandSeparator={true}
                name="donorGoal"
                value={donorGoal}
                onChange={(e) =>
                  handleChange("donorGoal", parseNumber(e.target.value))
                }
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const DonorPanelSection = (props) => {
  const { isDonorListPublic, donorListDisplay, handleChange, showAllExpanded } =
    props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <>
      <div className="raffles-form-section raffle-donor-panel-settings">
        <div className="raffles-section-title" onClick={handleToggleExpand}>
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Donor Panel
            </p>
          </div>
          {showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )}
        </div>
        <Collapse in={showExpanded}>
          <p className="accent-text-dark mb-16 line-height-double expanded-section">
            Select if you’d like to show the list of donors on your site. Choose
            if you’d like to show the ticket or donation amount.
          </p>
          <div className="mb-16">
            <Toggle
              name="isDonorListPublic"
              onChange={handleChange}
              options={[
                {
                  value: true,
                  display: "Show",
                },
                {
                  value: false,
                  display: "Hide",
                },
              ]}
              value={isDonorListPublic}
            />
          </div>
          <div>
            <Toggle
              name="donorListDisplay"
              onChange={handleChange}
              options={[
                {
                  value: "Donation",
                  display: "Donation amount",
                },
                {
                  value: "Tickets",
                  display: "Ticket amount",
                },
              ]}
              value={donorListDisplay}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const ProcessingFeeSection = (props) => {
  const { allowDonorToPayProcessingFee, handleChange, showAllExpanded } = props;
  const [showExpanded, setShowExpanded] = useState(showAllExpanded);
  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };
  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);
  return (
    <>
      <div className="raffles-form-section">
        <div className="raffles-section-title" onClick={handleToggleExpand}>
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Option for Donors to Donate the Processing Fee
            </p>
          </div>
          {showExpanded ? (
            <i className="material-icons accent-text-secondary xl-text">
              remove
            </i>
          ) : (
            <i className="material-icons accent-text-secondary xl-text">add</i>
          )}
        </div>
        <Collapse in={showExpanded}>
          <div className="expanded-section">
            <p className="accent-text-dark mb-16 line-height-double">
              With this option, donors can be offered the option to cover the
              cost of processing the donation! Many donors are happy to see you
              keep more of your money and therefore are happy to cover this fee
              for you. This feature can easily translate into a lot of savings!
            </p>
            <Toggle
              name="allowDonorToPayProcessingFee"
              onChange={handleChange}
              options={[
                {
                  value: true,
                  display: "Show",
                },
                {
                  value: false,
                  display: "Hide",
                },
              ]}
              value={allowDonorToPayProcessingFee}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};

const AboutGrandDrawSection = (props) => {
  const { currencyCode, prizes, cociTicketCCProcessingFee, fee, feeMax } =
    props;

  const currencyFlag =
    currencyCode === "CAD"
      ? "/images/ca-flag.svg"
      : currencyCode === "GBP"
      ? "/images/uk-flag.svg"
      : "/images/us-flag.svg";

  const prizeTitle = (prize) =>
    currencyCode === "CAD"
      ? prize.cadPrizeTitle
      : currencyCode === "GBP"
      ? prize.gbpPrizeTitle
      : prize.usdPrizeTitle;

  return (
    <div>
      <p className="raffles-form-section xxl-text fw-900">
        About the Grand Draw
      </p>
      <p className="medium-text fw-700 mb-16">Grand Draw Prizes</p>
      <div className="prize-list mb-24">
        {prizes &&
          prizes.map((prize) => (
            <div className="prize" key={prize.id}>
              <div className="mobile-prize-wrapper flex">
                <p className="prize-place">
                  {prize.place}
                  {nth(prize.place)} Place
                </p>
                <p className="fw-700 medium-text">{prizeTitle(prize)}</p>
              </div>
              <p className="small-text accent-text text-right">
                {pluralizeText("Winner", prize.numberOfWinners)}
              </p>
            </div>
          ))}
      </div>
      <div className="fees-info">
        <p className="accent-text-dark mb-16 flex flex-align-center">
          <img src={currencyFlag} alt="currencyFlag" className="mr-8" />
          Funds will be collected in {currencyCode}
        </p>
        <p className="accent-text-dark mb-16 line-height-double">
          A {formatCurrency(fee, currencyCode)} admin fee will be collected for
          each ticket. Up to {formatCurrency(feeMax, currencyCode)}. This fee
          covers the costs of Prizes, Marketing, Technology Costs, Legal Fees
          and Customer Support.
        </p>
        <p className="accent-text-dark mb-16 line-height-double">
          A 3% processing fee of{" "}
          {formatCurrency(cociTicketCCProcessingFee, currencyCode)} per ticket
          will also be collected for each credit card and PayPal transaction.
        </p>
      </div>
    </div>
  );
};
